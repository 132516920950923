import React from "react"
import Layout from "../components/layout"


const Rental = () => {
    return (
    <Layout>
        <div className="flex flex-col items-center p-4">
            <h1 className="text-2xl font-bold text-primary">BBQ Rental - No Catering </h1>
            <h3 className="p-2">
            Rent one of our mobile BBQs for your next event and do your own grilling 
            </h3>
        </div>
        <p className="p-2">
            Got a big bbq and just need a grill with no catering? Rent one of our mobile charcoal bbqs for your event, and be your own bbq pit boss.
            <br />
            <br />
            Our bbqs are an 8ft mobile charcoal bbq pit with the option to cook on grills or on a rotisserie. 
            This BBQ is large enough to cook ~350-400 individual pieces of chicken or pork at a time, or if using the rotisserie
            the BBQ can cook 2 whole pigs or ~400lbs of beef. 
        </p>
        <p className="p-2">

            Base cost is $850, and $50/hr for the duration of the rental period. <br />
            Rental bbqs are stored at our Truro and PEI locations. Price does not include delivery, but delivery can be arranged at an additional cost if required. <br />
            Price does not include any catering services. BBQ rental only. <br />
            Any damage to BBQ will be assessed on return and repair costs will be the responsiblity of the renter. <br />
            <br />
            Rental inventory is limited. Please <a href="mailto:sizzler@eastlink.ca">contact us to confirm availability.</a> <br />
            <br />
            <a href="mailto:sizzler@eastlink.ca">sizzler@eastlink.ca</a> 
        </p>
    </Layout>
    )
}

export default Rental